<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" md="12" sm="12">
        <v-card>
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('resourceOverviewPage.smsMessagesLeft') }}</span>
                <span class="d-block">{{ mainBannerData?.sms_total_remaining }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('resourceOverviewPage.flightsCheckLeft') }}</span>
                <span class="d-block">{{ mainBannerData?.flight_check_total_remaining }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn class="primary mr-2" elevation="2" text @click="buyMoreSms">
                  {{ $t('buttons.buyMoreSms') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.resourcesUsedByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="resourcesHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivingInNext3Days"
          :search="searchString"
          class="elevation-1"
          :items-per-page="10"
          multi-sort>
          <template v-slot:item.action_type="{ item }">
            {{ getTranslatedActionType(item.action_type) }}
          </template>
          <template v-slot:item.body="{ item }">
            {{ JSON.parse(item.body).message }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.resourcesUsed') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>
              </template>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { resourcesHeaders } from '@/mixins/data-table-headers';
import { formatDateTime } from '@/utils/formatDate';

export default {
  name: 'ResourceOverviewPage',
  props: [],
  components: {},
  data: () => ({
    searchString: '',
    mainBannerData: { sms_total_remaining: 0, flight_check_total_remaining: 0 },
    drivingInNext3Days: [],
  }),
  created() {
    this.getResourceData();
    this.getAuditData();
  },
  computed: {
    resourcesHeaders() {
      return resourcesHeaders(i18n);
    },
  },
  methods: {
    async getResourceData() {
      await this.$store.dispatch('resources/getResourceData').then((res) => {
        this.mainBannerData = res.data;
      });
    },
    async getAuditData() {
      await this.$store.dispatch('resources/getAuditData').then((res) => {
        this.drivingInNext3Days = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);
          return item;
        });
      });
    },
    getTranslatedActionType(actionType) {
      switch (actionType) {
        case 'sms':
          return i18n.t('resourceOverviewPage.sms');
        case 'flight_check':
          return i18n.t('resourceOverviewPage.flightCheck');
        default:
          return i18n.t('resourceOverviewPage.sms');
      }
    },
    buyMoreSms() {
      this.$store
        .dispatch('resources/generateMoreSmsLink')
        .then((res) => {
          window.open(res.data);
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
  },
  watch: {},
};
</script>
